/* eslint-disable no-console */
import RequestContactDialog from '@/pages/LandingPage/components/RequestContactDialog'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import useAlert from '../../hooks/useAlert'
import { useFullPageLoading } from '../../hooks/useFullPageLoading'
import { signOut } from '../../store/modules/auth/actions'
import { RootState } from '../../store/modules/rootReducer'
import { mapRoutes } from '../mapRoutes'
import { hasPermission, isAuthorized } from '../rules'
import AlertDialogSessionExpired from './AlertDialogSessionExpired'

interface AuthLayerProps {
  hasPublicAccess?: boolean
}

const AuthLayer: React.FC<React.PropsWithChildren<AuthLayerProps>> = ({
  children,
  hasPublicAccess,
}) => {
  const { isSigned, isLoading, isAuthenticationExpired, signFailureMessage } =
    useSelector((store: RootState) => store.auth)
  const { currentUser } = useSelector((store: RootState) => store.user)
  const [isRequestContactDialogOpen, setIsOpenRequestDialog] =
    React.useState(false)
  const [userEmail, setUserEmail] = React.useState('')

  const location = useLocation()
  const dispatch = useDispatch()
  const { setIsLoading } = useFullPageLoading()
  const { showAlert } = useAlert()

  const handleForceLogout = useCallback(() => {
    if (isSigned) {
      dispatch(signOut())
    }
  }, [dispatch, isSigned])

  useEffect(() => {
    if (isLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoading, setIsLoading])

  useEffect(() => {
    if (signFailureMessage) {
      if (signFailureMessage.includes('Empresa sem plano:')) {
        setUserEmail(signFailureMessage.split(':')[1])

        setIsOpenRequestDialog(true)
      } else {
        showAlert(signFailureMessage, 'error')
      }
    }
  }, [signFailureMessage, dispatch, showAlert])

  if (!isAuthenticationExpired) {
    if (!isAuthorized(isSigned, currentUser, hasPublicAccess)) {
      handleForceLogout()

      return (
        <Navigate
          to={mapRoutes.signIn.pathname + location.search}
          state={{
            redirectTo:
              location.pathname !== mapRoutes.signIn.pathname
                ? location.pathname
                : undefined,
          }}
          replace
        />
      )
    }

    if (!hasPermission(currentUser, location, hasPublicAccess)) {
      return <Navigate to={mapRoutes.dashboard.pathname} replace />
    }
  }

  return (
    <>
      {children}

      <AlertDialogSessionExpired
        open={isAuthenticationExpired}
        onCancel={handleForceLogout}
      />
      {isRequestContactDialogOpen && (
        <RequestContactDialog
          source="SignUpAtempt"
          open={isRequestContactDialogOpen}
          email={userEmail}
          onClickClose={setIsOpenRequestDialog}
        />
      )}
    </>
  )
}

export default AuthLayer
